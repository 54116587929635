<template>
  <b-modal
    id="modal-value"
    :title="titleModal"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-footer
    scrollable
    centered
    size="lg"
    @hidden="resetModal"
  >
    <template v-if="mxIsDevelopment">
      <FormulateInput
        v-model="inDevMode"
        name="devMode"
        type="checkbox"
        label="DEV"
      />
    </template>

    <FormulateForm
      ref="formReceiveValue"
      name="formReceiveValue"
      @submit="onConfirm"
    >
      <b-container>
        <b-row v-if="isAtm">
          <b-col class="d-flex flex-column justify-content-center align-items-center">
            <p class="h4">
              Valor a ser pago
            </p>
            <p class="h4 mb-0">
              {{ form.value | currency }}
            </p>
          </b-col>
        </b-row>

        <b-row v-if="!isAtm">
          <b-col>
            <div class="d-flex align-items-center">
              <FormulateInput
                id="modal_value-input_value"
                v-model="form.value"
                name="valueReceive"
                class="mr-1 input-size"
                :label="$t('Valor recebido')"
                type="text-number"
                currency="R$"
                :precision="2"
                validation="required|min:0.01"
                @input="onUpdateValue"
              />
              <!-- placement: tooltipPosition, -->
              <b-button
                id="modal_value-btn_reset_value"
                variant="danger"
                class="p-1"
                @click="onResetValue"
              >
                <feather-icon
                  icon="DeleteIcon"
                  size="20"
                />
              </b-button>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-between align-items-end">
              <p class="h5">
                Saldo restante:
              </p>
              <p class="h5">
                {{ balanceValueUpdated | currency }}
              </p>
            </div>
            <div
              v-if="change > 0"
              class="d-flex justify-content-between align-items-end"
            >
              <p class="h5">
                Troco:
              </p>
              <p class="h5">
                {{ change | currency }}
              </p>
            </div>
          </b-col>
        </b-row>

        <template v-if="hasPaymentMethodInterfaces">
          <b-row>
            <b-col>
              <FormulateInput
                id="method-interface"
                v-model="form.paymentMethodInterface"
                type="radio"
                :options="paymentMethodInterfacesOption"
                :label="$t('Pagar com')"
                :element-class="['d-flex', 'mt-1']"
              />
            </b-col>
          </b-row>
          <b-row v-if="isPOSMethod">
            <b-col md="12">
              <FormulateInput
                id="modal_value-input_nsu"
                v-model="form.nsu"
                name="nsu"
                type="number"
                class="required"
                validation="required|matches:/^[1-9][0-9]*/"
                :placeholder="$t('0000000')"
                :label="$t('Código de Autorização (NSU)')"
              />
            </b-col>
            <b-col
              v-if="!isPixMethod"
              md="12"
            >
              <FormulateInput
                id="modal_value-input_card_brand"
                v-model="form.cardBrand"
                :label="$t('Bandeira do cartão')"
                class="required"
                validation="required"
                name="cardBrand"
                type="e-radio-icon"
                :combo-options="cardBrands()"
              />
            </b-col>
          </b-row>

          <b-row v-if="isManualPixMethod">
            <b-col>
              <FormulateInput
                id="modal_value-input_document_titular"
                v-model="form.documentTitular"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                name="documentTitular"
                type="text"
                validation="optional|cpfCnpj"
                :validation-messages="{
                  cpfCnpj: $t('Documento inválido'),
                }"
                :placeholder="$t('Documento')"
                :label="$t('Documento do titular (CPF ou CNPJ)')"
              />
            </b-col>
          </b-row>
        </template>

        <b-row class="mt-1">
          <b-col class="d-flex justify-content-end">
            <e-button
              id="modal_value-btn_cancel"
              class="mr-1"
              variant="danger"
              :text="$t('Cancelar')"
              :text-shortcuts="hasShortcut ? ['ESC'] : []"
              @click="hideModal"
            />
            <e-button
              id="modal_value-btn_confirm"
              variant="primary"
              text="Confirmar"
              :text-shortcuts="hasShortcut ? ['CTRL', 'ENTER'] : []"
              :busy="isBusy"
              @click="onConfirm"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol, BButton } from 'bootstrap-vue'
import _ from 'lodash'
import { keyboardCode, paymentTypes, loadingOverlay, formulateHelper, cardDomain, devUtils } from '@/mixins'
import EButton from '@/views/components/EButton.vue'
import { roundDecimal } from '@/utils/number-utils'
import { getInitialReceiptForm } from '@/store/pages/pdv/pay-box'
import { mapGetters } from 'vuex'
import { Shortcuts } from 'shortcuts'
import { validateBr } from 'js-brasil'
import { initMonitoring } from '@/frontend-monitoring'

const { paymentMethodInterfaceEnum } = paymentTypes.data()

export default {
  components: { BModal, BContainer, BRow, BCol, BButton, EButton },

  mixins: [paymentTypes, cardDomain, keyboardCode, loadingOverlay, formulateHelper, devUtils],

  props: {
    saleUuid: {
      type: String,
      required: true,
    },
    balanceValue: {
      type: Number,
      required: true,
    },
    btnConfirmBusy: {
      type: Boolean,
      default: false,
    },

    isAtm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: getInitialReceiptForm(),
      titleModal: '',
      change: 0,
      balanceValueUpdated: 0,
      shortcuts: new Shortcuts(),
      localBusy: false,
      inDevMode: false
    }
  },
  computed: {
    ...mapGetters('pages/pdv/payBoxConfiguration', ['tefAgentConfigPayload', 'isTefEnabled']),
    ...mapGetters('pages/pdv/payBox', ['getCustomerDocument']),

    isBusy() {
      return this.localBusy || this.btnConfirmBusy
    },
    hasShortcut() {
      return !this.isAtm
    },

    paymentMethodInterfacesOption() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.paymentMethodInterface = this.isTefEnabled
        ? paymentMethodInterfaceEnum.TEF
        : paymentMethodInterfaceEnum.POS

      return this.paymentMethodInterfaces
        .filter(m => (this.isTefEnabled ? true : m !== 'Tef'))
        .map((m, index) => {
          const shortcut = this.paymentMethodInterfacesShorcuts[index].shortcut || ''
          const shortcutLabel = shortcut ? `[${shortcut}] ` : ''

          return {
            label: this.$t(`${shortcutLabel}${m}`),
            value: m,
          }
        })
    },
    paymentMethodInterfaces() {
      const hasInterfaces = [
        this.paymentTypeEnum.CREDIT_CARD,
        this.paymentTypeEnum.DEBIT_CARD,
        this.paymentTypeEnum.PIX,
      ]
      if (!hasInterfaces.includes(this.form.paymentMethod.method)) {
        return []
      }

      const interfaces = [this.paymentMethodInterfaceEnum.TEF, this.paymentMethodInterfaceEnum.POS]

      if (this.form.paymentMethod.method === this.paymentTypeEnum.PIX) {
        interfaces.push(this.paymentMethodInterfaceEnum.MANUAL)
      }

      return interfaces
    },
    paymentMethodInterfacesShorcuts() {
      if (this.paymentMethodInterfaces.length > 1) {
        return this.paymentMethodInterfaces
          .filter(m => (this.isTefEnabled ? true : m !== 'Tef'))
          .map((m, index) => ({
            shortcut: [this.keyCode.ctrl, index + 1].join('+'),
            handler: () => {
              this.form.paymentMethodInterface = m
              return true // Returning true because we don't want other handlers for the same shortcut to be called later
            },
          }))
      }
      return []
    },
    paymentMethodInterfaceProp() {
      return this.form.paymentMethodInterface
    },

    hasPaymentMethodInterfaces() {
      const result = this.paymentMethodInterfaces.length

      if (this.inDevMode) return result

      if (this.isAtm) return false

      return result
    },
    isPOSMethod() {
      return this.form.paymentMethodInterface === this.paymentMethodInterfaceEnum.POS
    },
    isManualPixMethod() {
      return this.form.paymentMethodInterface === this.paymentMethodInterfaceEnum.MANUAL
    },
    isPixMethod() {
      return this.form.paymentMethod?.method === this.paymentTypeEnum.PIX
    },
  },
  watch: {
    paymentMethodInterfaceProp(val) {
      this.$nextTick(() => {
        if (val === this.paymentMethodInterfaceEnum.POS) {
          this.focusInput('#modal_value-input_nsu')
        } else if (val === this.paymentMethodInterfaceEnum.MANUAL) {
          if (!this.form.documentTitular) {
            this.form.documentTitular = this.getCustomerDocument
          }

          this.$nextTick(() => {
            this.focusInput('#modal_value-input_document_titular')
          })
        }
      })
    },
  },
  methods: {

    // eslint-disable-next-line func-names
    onUpdateValue: _.debounce(function (value) {
      const changeValue = this.calcChange(this.balanceValue, value)
      if (changeValue > 0) {
        this.balanceValueUpdated = 0
        this.change = changeValue
      } else {
        this.balanceValueUpdated = roundDecimal(this.balanceValue - value)
        this.change = 0
      }
    }, 500),

    calcChange(total, value) {
      let change = 0
      const result = roundDecimal(total - value)
      if (result < 0) {
        change = Math.abs(result)
      } else {
        change = 0
      }
      return change
    },

    onResetValue() {
      this.form.value = 0
      this.balanceValueUpdated = this.balanceValue
      this.$formulate.resetValidation('formReceiveValue')
    },

    /** method for atm payment, it not show modal */
    async payOnAtm(paymentMethod) {
      this.resetModal()

      if (!this.isAtm) {
        this.showWarning({ message: 'Este pagamento funciona apenas no ATM.' })
        return
      }

      this.form = { ...this.form, paymentMethod, value: this.balanceValue }

      if (this.isTefEnabled) {
        this.form.paymentMethodInterface = paymentMethodInterfaceEnum.TEF
      } else if (this.mxIsDevelopment) {
        this.form.paymentMethodInterface = paymentMethodInterfaceEnum.POS
        this.form.nsu = '1100220033'
        this.form.cardBrand = 'MASTERCARD'
      } else {
        this.showWarning({ message: 'Para realizar pagamentos, o TEF deve estar habilitado.' })
        return
      }

      await this.onConfirm()
    },

    showModal(paymentMethod) {
      this.$bvModal.show('modal-value')
      this.resetModal()

      this.form = { ...this.form, paymentMethod, value: this.balanceValue }

      if (!this.paymentMethodInterfaces.length) {
        this.form.paymentMethodInterface = this.paymentMethodInterfaceEnum.MANUAL
      }

      if (this.hasShortcut) {
        this.addModalShortcuts()
      }

      this.$nextTick(() => {
        this.titleModal = `Pagar com: ${this.form.paymentMethod.name}`
        if (
          this.form.paymentMethodInterface !== this.paymentMethodInterfaceEnum.POS &&
          this.form.paymentMethodInterface !== this.paymentMethodInterfaceEnum.MANUAL
        ) {
          this.focusInput('#modal_value-input_value')
        }
      })
    },
    hideModal() {
      this.shortcuts.reset()
      this.$bvModal.hide('modal-value')
    },

    resetModal() {
      this.form = getInitialReceiptForm()
      this.change = 0

      this.balanceValueUpdated = 0
    },

    async onConfirm() {
      await new Promise(resolve => setTimeout(() => resolve(), 100))
      if (this.isBusy) return

      try {
        this.localBusy = true

        if (this.$refs.formReceiveValue) {
          this.$refs.formReceiveValue.showErrors()
        }

        if (!this.validation()) return

        let tefResult
        if (
          this.paymentMethodInterfaces.length &&
          this.form.paymentMethodInterface === this.paymentMethodInterfaceEnum.TEF
        ) {
          await initMonitoring({ isTefAction: true })

          const tefPayload = {
            saleId: this.saleUuid,
            amount: this.form.value,
          }

          this.showLoadingOverlay(this.$t('PAY_BOX_SALE.TEF.START_TEF_PAYMENT'))

          try {
            switch (this.form.paymentMethod.method) {
              case this.paymentTypeEnum.CREDIT_CARD:
                tefResult = await window.electronAPI.tef.creditPayment(
                  this.tefAgentConfigPayload,
                  tefPayload
                )
                break
              case this.paymentTypeEnum.DEBIT_CARD:
                tefResult = await window.electronAPI.tef.debitPayment(
                  this.tefAgentConfigPayload,
                  tefPayload
                )
                break
              case this.paymentTypeEnum.PIX:
                // eslint-disable-next-line no-case-declarations
                let callback = qrResult => {
                  this.showLoadingOverlayV2({
                    text: this.$t('QR Code para pagamento'),
                    image: `data:image/png;base64,${qrResult.qrCodeContent}`,
                  })
                }
                window.electronAPI.tef.displayQrCode(callback)

                tefResult = await window.electronAPI.tef.pixPayment(
                  this.tefAgentConfigPayload,
                  tefPayload
                )
                callback = () => { }
                break
              default:
                throw new Error(
                  `Payment Method: ${this.form.paymentMethod.method} not implemented yet !`
                )
            }

            this.form.nsu = tefResult.nsu
            this.form.cardBrand = tefResult.cardBrand
            this.hideLoadingOverlay()
          } catch (error) {
            if (error.canceled) {
              this.showWarning({
                title: this.$t('Pagamento TEF'),
                message: this.$t(
                  error.displayMessage || 'Operação cancelada no equipamento PINPAD'
                ),
              })
            } else {
              console.error('tef-error', error)
              this.showError({ error })
            }
            this.hideModal()
            this.hideLoadingOverlay()
            return
          }
        }

        this.$emit('click-confirm', {
          ...this.form,
          change: this.calcChange(this.balanceValue, this.form.value),
          tefPayment: tefResult,
        })
      } finally {
        this.localBusy = false
      }
    },

    addModalShortcuts() {
      const { esc, ctrl, enter } = this.keyCode

      this.shortcuts.add([
        {
          shortcut: esc,
          handler: () => {
            this.hideModal()
            return true
          },
        },
        {
          shortcut: [ctrl, enter].join('+'), // get 'Enter' and 'NumpadEnter' keys
          handler: () => {
            this.onConfirm()
            return true
          },
        },
      ])
      this.shortcuts.add(this.paymentMethodInterfacesShorcuts)
    },

    validation() {
      const { value, nsu, documentTitular, cardBrand } = this.form

      if (!value || value < 0.01) return false
      if (this.hasPaymentMethodInterfaces) {
        const hasEmptyPOSFields = !nsu || (!this.isPixMethod && !cardBrand)
        if (this.isPOSMethod && hasEmptyPOSFields) return false

        if(this.isPOSMethod && nsu <= 0) return false
        if (this.isManualPixMethod && documentTitular && !validateBr.cpfcnpj(documentTitular || ''))
          return false
      }

      if (this.form.paymentMethod.method !== this.paymentTypeEnum.CASH) {
        const change = this.calcChange(this.balanceValue, this.form.value)
        if (change > 0) {
          this.showError({
            title: this.$t('Troco não permitido'),
            message: this.$t(
              'PAY_BOX_SALE.PAYMENT.UI.EXCHANGE_IS_ONLY_ALLOWED_IN_CASH_PAYMENT_METHOD'
            ),
          })
          return false
        }
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.input-size {
  font-size: 1.4rem;
}

.btn-shortcut {
  padding: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
